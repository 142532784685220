<template>
    <div data-scroll :data-scroll-id="name" class="about-brand">
        <brand-name-slider>
            <div
                v-for="(item, index) in brandLogos"
                class="brand-logo-container"
                :key="index"
                v-html="item.logo_svg"
            ></div>
        </brand-name-slider>
        <div v-observer class="brand-content">
            <div class="left">
                <div class="container">
                    <div
                        v-for="(item, index) in mainDescriptions"
                        :key="index"
                        class="item"
                    >
                        <h2 class="header">{{ item.title }}</h2>
                        <p class="description" v-html="item.text"></p>
                    </div>
                </div>
            </div>
            <div class="right">
                <img :src="upImage.image" class="image" alt="" />
            </div>
        </div>
        <div v-observer class="images-container">
            <image-scale
                v-observer
                v-for="(item, index) in downImages"
                :item="item"
                :key="index"
                :index="index"
            />
            <div class="cover-image">
                <img
                    :src="coverImage"
                    data-scroll
                    data-scroll-id="inside-scale"
                    class="image"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageScale from "@/components/custom/ImageScale";
import BrandNameSlider from "@/components/PageComponents/BrandNameSlider";
import first from "lodash/first";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        ImageScale,
        BrandNameSlider
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        mainItem() {
            return first(this.data?.list);
        },
        brandLogos() {
            return this.mainItem?.logos?.tabs;
        },
        mainDescriptions() {
            return this.mainItem?.description?.tabs?.map(item => ({
                title: item.title,
                text: item.content
            }));
        },
        coverImage() {
            return this?.mainItem?.cover_image?.[0]?.devices[this.device?.type];
        },
        images() {
            return this.mainItem?.images?.map(item => ({
                image: item?.devices?.[this.device?.type],
                description: item?.title?.description,
                title: item?.title?.title,
                teaser: item?.title?.teaser
            }));
        },
        downImages() {
            return this.images?.slice(1);
        },
        upImage() {
            return this.images?.[0];
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.about-brand /deep/ {
    .brand-content {
        display: flex;
        align-items: flex-start;
        padding: 0 375px;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            padding: 0 165px;
        }

        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            padding: 0 128px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            padding: 0 47px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            padding: 0 47px;
            flex-direction: column-reverse;
        }

        @media only screen and (max-width: 767px) {
            padding: 0 16px;
            flex-direction: column-reverse;
        }

        .container {
            .header {
                font-size: 34px;
                color: #ea5053;
                transition: all 0.6s 0.1s $ease-out;
                text-align: left;
                transform: translateY(20px);

                @media only screen and (max-width: 767px) {
                    font-size: 21px;
                }
            }

            .description {
                font-size: 21px;
                color: #0a141c;
                display: block;
                margin-top: 25px;
                text-align: left;
                transition: all 0.6s 0.1s $ease-out;
                transform: translateY(20px);

                p,
                span,
                h2,
                h1,
                div {
                    font-family: "Noto-Sans", "Noto-Sans-georgian";
                }

                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }

            .item {
                &:last-child {
                    margin-top: 30px;

                    .header {
                        color: #7e4694;
                    }
                }
            }
        }

        .right {
            overflow: hidden;
            width: 445px;
            margin-left: 178px;
            min-width: 445px;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                margin-left: 125px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                width: 450px;
                min-width: 450px;
                margin-left: 30px;
            }
            @media only screen and (max-width: 1023px) {
                width: 100%;
                min-width: 100%;
                margin-left: 0;
                margin-bottom: 55px;
                height: 377px;
            }
            @media only screen and (max-width: 767px) {
                height: 213px;
            }
        }

        .image {
            clip-path: inset(0px 0px 90%);
            transform: scale(1.2);
            transition: all 0.6s 0.2s $ease-out;
            width: 100%;
            @media only screen and (max-width: 1024px) and (min-width: 768px) {
                object-fit: cover;
            }

            @media only screen and (max-width: 767px) {
                max-height: 350px;
                object-fit: cover;
            }
        }

        &.observed {
            .header {
                transform: translateY(0);
            }

            .description {
                transform: translateY(00);
            }

            .image {
                transform: scale(1);
                clip-path: inset(0px);
            }
        }
    }

    .images-container {
        position: relative;
        margin-top: 204px;

        .image-speed {
            position: absolute;
            z-index: 1;

            .image-container {
                width: 100%;
                height: 100%;
                overflow: hidden;
                transition: all 0.6s 0.1s $ease-out;
            }

            &.observed {
                .image-container {
                    clip-path: inset(30px) !important;
                    @media only screen and (max-width: 1023px) {
                        clip-path: inset(15px) !important;
                    }
                }
            }

            &:nth-of-type(1) {
                left: 225px;
                top: -100px;
                width: 479px;

                @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                    left: 165px;
                    width: 410px;
                }
                @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                    left: 128px;
                    width: 410px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    left: 47px;
                    width: 350px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    left: 47px;
                    width: 245px;
                }
                @media only screen and (max-width: 767px) {
                    width: 210px;
                    left: 0;
                }
            }

            &:nth-of-type(2) {
                right: 150px;
                top: -100px;
                width: 405px;

                @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                    right: 165px;
                    width: 315px;
                    top: -70px;
                }

                @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                    right: 128px;
                    width: 270px;
                    top: -70px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    width: 270px;
                    top: -50px;
                    right: 47px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 191px;
                    top: -50px;
                    right: 31px;
                }
                @media only screen and (max-width: 767px) {
                    width: 190px;
                    top: -30px;
                    right: 0;
                }

                .image-container {
                    clip-path: inset(30px 30px 80% 30px);
                }
            }

            &:nth-of-type(3) {
                right: 450px;
                bottom: 34px;
                width: 630px;

                @media only screen and (max-width: 1750px) and (min-width: 1440px) {
                    right: 450px;
                    width: 410px;
                }
                @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                    width: 410px;
                    right: 413px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    width: 350px;
                    right: 287px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 245px;
                    right: 177px;
                    bottom: 21px;
                }
                @media only screen and (max-width: 767px) {
                    width: 220px;
                    right: 1px;
                    bottom: 21px;
                }

                .image-container {
                    clip-path: inset(30px 30px 70% 30px);
                }
            }
        }

        .cover-image {
            width: 100%;
            height: 610px;
            background: #f9b7b3;
            overflow: hidden;

            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                height: 458px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                height: 434px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                height: 325px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                height: 244px;
            }
            @media only screen and (max-width: 767px) {
                height: 244px;
            }

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.2;
            }
        }
    }
}
</style>
